
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import DateUtils from '@/assets/js/utils/DateUtils';
import Formatter from '@/assets/js/utils/Formatter';
import Utils from '@/assets/js/utils/Utils';
import NoteBox from '@/components/basics/NoteBox.vue';

import Tabs from '@/components/basics/Tabs.vue';
import { Port } from '@/modules/ctx-report-preview/Port';

import type { ReportConfig } from '@/modules/ctx-report-preview/types';
import {
    ReportGenerationInterval,
    ReportGenerationReferenceDate,
    ReportType,
} from '@/modules/ctx-report-preview/types';

import BaseDialog from '@/modules/shared/components/dialogs/BaseDialog.vue';
import InputCheckbox from '@/modules/shared/components/form/InputCheckbox.vue';
import InputDate from '@/modules/shared/components/form/InputDate.vue';
import InputSelect from '@/modules/shared/components/form/InputSelect.vue';
import InputText from '@/modules/shared/components/form/InputText.vue';
import LoadingAnimation from '@/modules/shared/components/loading-animation/LoadingAnimation.vue';
import LoadingAnimationSmall from '@/modules/shared/components/loading-animation/LoadingAnimationSmall.vue';
import LicenseGuard from '@/modules/shared/components/util/LicenseGuard.vue';
import { LicenseFeature } from '@/modules/shared/types';

enum Tab {
    GENERAL = 'ctx-reporting.tab-general',
    TITLE = 'ctx-reporting.tab-title',
    AUTO_GENERATION = 'ctx-reporting.auto-generate',
    SHARING = 'ctx-reporting.tab-sharing',
}

@Component({
    name: 'ReportConfigDlg',
    components: {
        LicenseGuard,
        NoteBox,
        LoadingAnimation,
        InputDate,
        InputCheckbox,
        InputSelect,
        InputText,
        LoadingAnimationSmall,
        Tabs,
        BaseDialog,
    },
})
export default class ReportConfigDlg extends Vue {

    @Prop({ required: true })
    public readonly dashboardKey!: string;

    @Prop({ required: false, default: null })
    public readonly portfolioKey!: string|null;

    private readonly ReportGenerationInterval = ReportGenerationInterval;

    private readonly reportTypeOptions: SelectBoxOption[] = [
        { value: ReportType.Month, displayName: 'ctx-reporting.report-type.month' },
        // { value: ReportType.LastMonth, displayName: 'ctx-reporting.report-type.last-month' },
        // { value: ReportType.PenultimateMonth, displayName: 'ctx-reporting.report-type.penultimate-month' },
        // { value: ReportType.LastYear, displayName: 'ctx-reporting.report-type.last-year' },
        { value: ReportType.Year, displayName: 'ctx-reporting.report-type.year' },
        { value: ReportType.Custom, displayName: 'ctx-reporting.report-type.custom' },
    ];

    private LicenseFeature = LicenseFeature;
    private Tab = Tab;
    private hasReportingLicense: boolean = false;
    private useCustomTitle: boolean = false;

    private selectedTab: Tab = this.tabs[0];
    private saving: boolean = false;
    private reportConfigOriginal: ReportConfig|null = null;
    private reportConfig: ReportConfig|null = null;
    private fromDate: Date = new Date();
    private toDate: Date = new Date();
    private overrideTime: boolean = false;

    private newEmailAddress: string = '';
    private newEmailAddressErrors: string[] = [];
    private errors: {[key: string]: string[]} = {};

    public async created(): Promise<void> {
        await this.fetchReportConfig();
        this.hasReportingLicense = await Port.user.getUserHasLicenseFeature(LicenseFeature.REPORTING);
    }

    @Watch('reportConfig', { deep: true })
    private validate(): void {
        if (this.reportConfig) {
            this.errors = Port.reports.validateReportConfig(this.reportConfig);
        }
    }

    @Watch('newEmailAddress')
    private validateEmail(): void {
        if (this.reportConfig) {
            this.newEmailAddressErrors = Port.reports.validateEmail(this.newEmailAddress, this.reportConfig);
        }
    }

    private get autoGenerationIntervallOptions(): SelectBoxOption[] {
        const generationDate = this.reportConfig?.generateAt || new Date();
        const monthly = this.$t('ctx-reporting.auto-generate.interval.monthly', { date: Formatter.formatDate(generationDate, 'dd.') });
        const annually = this.$t('ctx-reporting.auto-generate.interval.annual', { date: Formatter.formatDate(generationDate, 'dd. MMMM') });
        return [
            { value: ReportGenerationInterval.Monthly, displayName: monthly.toString() },
            { value: ReportGenerationInterval.Annual, displayName: annually.toString() },
        ];
    }

    private get referenceDateOptions(): SelectBoxOption[] {
        const generationDate = DateUtils.cropToPrecision(this.reportConfig?.generateAt || new Date(), 'months');
        if (this.reportConfig?.generateInterval === ReportGenerationInterval.Annual) {
            const currentYear = Formatter.formatDate(generationDate, 'yyyy');
            const lastYear = Formatter.formatDate(DateUtils.subtract(generationDate, { years: 1 }), 'yyyy');
            const yearBeforeLast = Formatter.formatDate(DateUtils.subtract(generationDate, { years: 2 }), 'yyyy');
            return [
                { value: ReportGenerationReferenceDate.Current, displayName: currentYear },
                { value: ReportGenerationReferenceDate.Previous, displayName: lastYear },
                { value: ReportGenerationReferenceDate.PrePrevious, displayName: yearBeforeLast },
            ];
        }
        const currentMonth = Formatter.formatDate(generationDate, 'MMMM');
        const lastMonth = Formatter.formatDate(DateUtils.subtract(generationDate, { months: 1 }), 'MMMM');
        const monthBeforeLast = Formatter.formatDate(DateUtils.subtract(generationDate, { months: 2 }), 'MMMM');
        return [
            { value: ReportGenerationReferenceDate.Current, displayName: currentMonth },
            { value: ReportGenerationReferenceDate.Previous, displayName: lastMonth },
            { value: ReportGenerationReferenceDate.PrePrevious, displayName: monthBeforeLast },
        ];
    }

    private get languageOptions(): SelectBoxOption[] {
        const locales: string[] = this.$messages.locales;
        const options: SelectBoxOption[] = [];
        const languageDisplayNames = new Intl.DisplayNames([this.$i18n.locale], { type: 'language' });
        locales.forEach((locale) => options.push({ value: locale, displayName: languageDisplayNames.of(locale) ?? '' }));
        return options;
    }

    public get hasErrors(): boolean {
        return Object.keys(this.errors).length > 0;
    }

    public get showDateWarning(): boolean {
        if (this.reportConfig?.generateForReferenceDate === ReportGenerationReferenceDate.Current) {
            return true;
        }
        if (this.reportConfig?.generateForReferenceDate === ReportGenerationReferenceDate.Previous) {
            const date = this.reportConfig.generateAt || new Date();
            if (this.reportConfig.generateInterval === ReportGenerationInterval.Annual) {
                return date.getDate() < 3 && date.getMonth() === 0;
            }
            return date.getDate() < 3;
        }
        return false;
    }

    private get tabs(): Tab[] {
        const tabs = [Tab.GENERAL, Tab.TITLE, Tab.AUTO_GENERATION];
        if (this.reportConfig?.generateAutomatically) {
            tabs.push(Tab.SHARING);
        }
        return tabs;
    }

    private addEmailAddress(): void {
        if (this.reportConfig && this.newEmailAddressErrors.length === 0 && this.newEmailAddress.trim().length > 0) {
            this.reportConfig.reportEmails.push(this.newEmailAddress);
            this.newEmailAddress = '';
        }
    }

    private removeEmailAddress(index: number): void {
        if (this.reportConfig) {
            this.reportConfig.reportEmails.splice(index, 1);
        }
    }

    private async fetchReportConfig(): Promise<void> {
        this.reportConfig = await Port.reports.getReportConfig(this.dashboardKey, this.$i18n.locale);
        this.useCustomTitle = this.reportConfig.title !== '' || this.reportConfig.subtitle !== '';
        this.reportConfigOriginal = Utils.deepCopy(this.reportConfig);
    }

    @Watch('reportConfig.reportType')
    private async updateDefaultTitle(): Promise<void> {
        if (this.reportConfig) {
            this.reportConfig.defaultTitle = await Port.reports.getAutomaticReportTitle(this.reportConfig);
            this.reportConfig.defaultSubTitle = await Port.reports.getAutomaticReportSubtitle(this.reportConfig);
            if (!this.reportConfig.generateAutomatically) {
                this.reportConfig.generateAt = this.defaultGenerationDate;
                switch (this.reportConfig.reportType) {
                    case ReportType.Year:
                    case ReportType.LastYear:
                        this.reportConfig.generateInterval = ReportGenerationInterval.Annual;
                        break;
                    case ReportType.LastMonth:
                    case ReportType.PenultimateMonth:
                    case ReportType.Custom:
                    default:
                        this.reportConfig.generateInterval = ReportGenerationInterval.Monthly;
                        break;
                }
            }
        }
    }

    @Watch('reportConfig.generateAutomatically')
    private async updateReportGenerationDate(): Promise<void> {
        if (this.reportConfig && !this.reportConfig.generateAt && this.reportConfig.generateAutomatically) {
            this.reportConfig.generateAt = this.defaultGenerationDate;
        }
    }

    private get defaultGenerationDate(): Date {
        let date = new Date();
        if (date.getDate() >= 7) {
            date = DateUtils.add(date, { months: 1 });
        }
        date.setDate(7);
        return date;
    }

    @Watch('useCustomTitle')
    private updateTitle(): void {
        if (!this.reportConfig) {
            return;
        }

        if (this.useCustomTitle) {
            this.reportConfig.subtitle = this.reportConfig.defaultSubTitle;
            this.reportConfig.title = this.reportConfig.defaultTitle;
        } else {
            this.reportConfig.title = '';
            this.reportConfig.subtitle = '';
        }
    }

    private hasAnyChanges(): boolean {
        return JSON.stringify(this.reportConfig) !== JSON.stringify(this.reportConfigOriginal);
    }

    private save(): void {
        if (!this.hasAnyChanges()) {
            this.close();
            return;
        }
        if (this.reportConfig) {
            this.saving = true;
            Port.reports.saveReportConfig(this.reportConfig)
                .then(() => {
                    this.$emit('close');
                    this.$emit('change');
                    this.$emit('submit');
                })
                .finally(() => this.saving = false);
        }
    }

    private close() {
        this.$emit('close');
    }
}
