import SelectBoxOption from '@/assets/js/models/SelectBoxOption';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';
import DateUtils from '@/assets/js/utils/DateUtils';
import Formatter from '@/assets/js/utils/Formatter';
import MathUtils from '@/assets/js/utils/MathUtils';
import Utils from '@/assets/js/utils/Utils';
import type { ChartDataRepresentation } from '@/clients/dashboardapi/v2';
import { Aggregation, Resolution, ResourceType } from '@/clients/dashboardapi/v2';
import type { WidgetConfig, WidgetConfigMetric } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import { i18n } from '@/plugins/i18n';
import { TableColumn } from '@/modules/shared/components/table/types';
import { DataGroupType } from '../types/DataGroupType';
import type { FlattenedResource } from '../types/FlattenedResource';
import { WidgetUtils } from './WidgetUtils';

export class WidgetDataUtils {
    public static readonly RESOURCE_NAME_AND_METRIC_NAME_SEPARATOR = '\n';

    /**
     * Flattens the nested arrays for axis > metrics > resources to a 1 level resource array, that contains all
     * relevant information of axis, metric and resource. Used to avoid complex nested loops wherever the resources
     * of a widget need to be iterated.
     */
    public static flattenResources(widget: WidgetConfig): FlattenedResource[] {
        const resources: FlattenedResource[] = [];
        for (let axisIndex = 0; axisIndex < widget.axis.length; axisIndex++) {
            const axis = widget.axis[axisIndex];
            const axisResources = axis.metrics.flatMap((metric) => metric.resources);
            const axisResourceCountPortfolio = axisResources.filter((resource) => resource.type !== ResourceType.Anonymous).length;
            const axisResourceCountAnonymous = axisResources.length - axisResourceCountPortfolio;

            let axisResourceIndex = -1;
            for (let metricIndex = 0; metricIndex < axis.metrics.length; metricIndex++) {
                const metric = axis.metrics[metricIndex];
                for (let resourceIndex = 0; resourceIndex < metric.resources.length; resourceIndex++) {
                    axisResourceIndex++;
                    const resource = metric.resources[resourceIndex];

                    resources.push({
                        widgetAxisIndex: axisIndex,
                        metricIndex: metricIndex,
                        metricResourceIndex: resourceIndex,
                        axisResourceIndex: axisResourceIndex,
                        axisResourceCount: axisResources.length,
                        axisResourceCountPortfolio: axisResourceCountPortfolio,
                        axisResourceCountAnonymous: axisResourceCountAnonymous,
                        seriesName: resource.seriesName,
                        defaultSeriesName: WidgetUtils.getDefaultSeriesName(widget, metric, resource),

                        axisName: axis.name,
                        axisConfig: axis.config,

                        metricUUID: metric.uuid,
                        metricName: metric.metricName,
                        metricKey: metric.metricKey,
                        metricCategory: metric.metricCategory,
                        metricUnit: metric.metricUnit,
                        aggregationOverTime: metric.aggregationOverTime,

                        resourceUUID: resource.uuid,
                        resourceKey: resource.resourceKey,
                        resourceFilters: resource.resourceFilters,
                        resourceType: resource.type,
                        resourceName: resource.resourceName,
                        aggregationOverGenerators: resource.aggregationOverGenerators,
                        resourceConfig: resource.config,
                        timeOverride: resource.timeOverride,
                    });
                }
            }
        }
        return resources;
    }

    public static toFlattenedResource(metric: WidgetConfigMetric, resourceIndex: number): FlattenedResource {
        const resource = metric.resources[resourceIndex] || {};
        return {
            // fields from metric
            metricUUID: metric.uuid,
            metricName: metric.metricName,
            metricKey: metric.metricKey,
            metricCategory: metric.metricCategory,
            metricUnit: metric.metricUnit,
            aggregationOverTime: metric.aggregationOverTime,

            // fields from resource
            seriesName: resource.seriesName,
            resourceUUID: resource.uuid,
            resourceKey: resource.resourceKey,
            resourceFilters: resource.resourceFilters,
            resourceType: resource.type,
            resourceName: resource.resourceName,
            aggregationOverGenerators: resource.aggregationOverGenerators,
            resourceConfig: resource.config,
            timeOverride: resource.timeOverride,

            // these fields cannot be determined from metric and resource alone
            widgetAxisIndex: -1,
            metricIndex: -1,
            metricResourceIndex: -1,
            axisResourceIndex: -1,
            axisResourceCount: -1,
            axisResourceCountPortfolio: -1,
            axisResourceCountAnonymous: -1,
            defaultSeriesName: '',
            axisName: '',
            axisConfig: undefined,
        };
    }

    public static mapChartDataToTable(data: ChartDataRepresentation[], widget: WidgetConfig): number[][] {
        // create a map with time as key, so all values are mapped to the correct timestamp
        const map = WidgetDataUtils.getMapWithCroppedTimestampsForWidgetAndChartData(widget, data);

        // map to two dimensional array
        const table: number[][] = [];
        map.forEach((values: number[], time: number) => {
            // WPXD-575 filter out all rows, that only have null values (set by server to fill missing data)
            if (widget.resolution !== Resolution.Min10 || values.filter((v) => v !== undefined && v !== null).length > 0) {
                table.push([time].concat(values));
            }
        });

        // sort by time which is in the first column of the row
        return table.sort((a, b) => (a[0] || 0) - (b[0] || 0));
    }

    public static mapResourcesAndChartDataToTable(resources: FlattenedResource[], data: ChartDataRepresentation[]): (number | string)[][] {
        const table: (number | string)[][] = [];

        resources.forEach((resource, index) => {
            const resourceAndMetricColumnData = `${resource.resourceName}${WidgetDataUtils.RESOURCE_NAME_AND_METRIC_NAME_SEPARATOR}${resource.metricName}`;
            const chartColumnData = data.map((dataToMap) => dataToMap.y)[index];
            table.push([resourceAndMetricColumnData, ...chartColumnData]);
        });

        return table;
    }

    public static async formatTable(
        rows: (number|string|undefined)[][],
        columns: TableColumn[],
        resources: (FlattenedResource|undefined)[],
        widget: WidgetConfig,
    ): Promise<string[][]> {
        const data = rows;
        //  format body
        let dateFormat: string = 'dd.MM.yyyy hh:mm';
        switch (widget.resolution) {
            case Resolution.Monthly: dateFormat = 'MMM yyyy'; break;
            case Resolution.Daily: dateFormat = 'dd.MM.yyyy'; break;
            case Resolution.Min10: dateFormat = 'dd.MM.yyyy hh:mm'; break;
            case Resolution.Automatic: dateFormat = 'dd.MM.yyyy hh:mm'; break;
            default:
        }
        const formattedData = data.map((row, rowIndex: number) => row.map((value, colIndex) => {
            const renderFunction = columns[colIndex].renderHTML;
            if (renderFunction) {
                return renderFunction(value, row, rowIndex, colIndex);
            }

            if (typeof value === 'string') {
                return value;
            }

            if (colIndex === 0) {
                return Formatter.formatDate(row[colIndex], dateFormat);
            }

            const metric = resources[colIndex];
            if (metric?.metricUnit) {
                return Formatter.formatMaxDigitsForUnit(value, metric.metricUnit);
            }

            return Formatter.formatMaxDigits(value, 2);
        }));

        formattedData.unshift(columns.map((column) => column.title));

        return formattedData;
    }

    public static generateAggregationDataForResources(rows: (number|string|undefined)[][], resources: FlattenedResource[], isTransposedData: boolean): (number|undefined)[] {
        const aggregations = resources.map((resource) => resource.aggregationOverTime);
        return this.generateAggregationData(rows, aggregations, isTransposedData);
    }

    public static generateAggregationData(rows: (number|string|undefined)[][], aggregations: Aggregation[], isTransposedData: boolean): (number|undefined)[] {
        const columns = isTransposedData ? rows : ArrayUtils.rotateArray(rows);
        const resourceOffset = columns.length - aggregations.length;
        const aggregationRow = columns.map((col, index) => {
            const colIndex = isTransposedData ? index + 1 : index;
            // first column is timestamp column, which should not be aggregated
            if (colIndex === 0 || colIndex < resourceOffset) {
                return undefined;
            }
            const nonNullValues = col.filter(
                (v): v is number => v !== undefined && v !== null && typeof v === 'number',
            );
            switch (aggregations[colIndex - resourceOffset]) {
                case Aggregation.Avg: return MathUtils.avg(nonNullValues);
                case Aggregation.Count: return nonNullValues.length;
                case Aggregation.Min: return MathUtils.min(nonNullValues);
                case Aggregation.Max: return MathUtils.max(nonNullValues);
                case Aggregation.Sum: return MathUtils.sum(nonNullValues);
                case Aggregation.None: return undefined;
                default: return MathUtils.avg(nonNullValues);
            }
        });
        return aggregationRow;
    }

    public static async generateTitleRowForResources(resources: FlattenedResource[], groupByType: DataGroupType = DataGroupType.NONE): Promise<string[]> {
        const titles: string[] = await Promise.all(resources.map(async (resource) => {
            const metricName = await WidgetUtils.getMetricName(resource.metricKey, true);
            const resourceName = resource.seriesName || resource.resourceName;
            switch (groupByType) {
                case DataGroupType.DATASOURCE: return metricName;
                case DataGroupType.METRIC: return resourceName;
                case DataGroupType.NONE:
                default:
                    return `${resourceName}<br><i class="font-light mr-1">${metricName}</i>`;
            }
        }));
        // add time row title
        titles.unshift(i18n.t('time').toString());
        return titles;
    }

    public static async generateTitleRowForData(widget: WidgetConfig, data: ChartDataRepresentation[], groupByType: DataGroupType = DataGroupType.NONE): Promise<string[]> {
        let groupTitle: string;

        switch (groupByType) {
            case DataGroupType.DATASOURCE:
                groupTitle = 'widgets.title.datagroup.datasource';
                break;
            case DataGroupType.METRIC:
                groupTitle = 'widgets.title.datagroup.metric';
                break;
            default:
                groupTitle = 'widgets.title.datagroup.datasource-or-metric';
        }

        // create a map with time as key, so all values are mapped to the correct timestamp
        const map = this.getMapWithCroppedTimestampsForWidgetAndChartData(widget, data);

        const timestamps: number[] = [];
        map.forEach((values: number[], time: number) => {
            // WPXD-575 filter out all rows, that only have null values (set by server to fill missing data)
            if (widget.resolution !== Resolution.Min10 || values.filter((v) => v !== undefined && v !== null).length > 0) {
                timestamps.push(time);
            }
        });

        // sort by time which is in the first column of the row
        const sortedTimestamps = timestamps.sort((a, b) => (a || 0) - (b || 0));

        let dateFormat: string = 'dd.MM.yyyy hh:mm';
        switch (widget.resolution) {
            case Resolution.Monthly: dateFormat = 'MMM yyyy'; break;
            case Resolution.Daily: dateFormat = 'dd.MM.yyyy'; break;
            case Resolution.Min10: dateFormat = 'dd.MM.yyyy hh:mm'; break;
            case Resolution.Automatic: dateFormat = 'dd.MM.yyyy hh:mm'; break;
            default:
        }
        const titles: string[] = sortedTimestamps.map((timestamp) => Formatter.formatDate(timestamp, dateFormat));
        // add translated group title
        titles.unshift(i18n.t(groupTitle).toString());
        return titles;
    }

    private static getMapWithCroppedTimestampsForWidgetAndChartData(widget: WidgetConfig, data: ChartDataRepresentation[]) {
        const map = new Map<number, number[]>();

        let timestampPrecision: 'months' | 'days' | 'minutes' = 'minutes';
        switch (widget.resolution) {
            case Resolution.Monthly:
                timestampPrecision = 'months';
                break;
            case Resolution.Daily:
                timestampPrecision = 'days';
                break;
            default:
        }

        data.forEach((series, columnIndex: number) => {
            series.x.forEach((timestamp, index) => {
                const timestampCropped = DateUtils.cropToPrecision(new Date(timestamp), timestampPrecision)
                    .getTime();
                const columns = map.get(timestampCropped) || Array<number>(data.length);
                columns[columnIndex] = series.y[index];
                map.set(timestampCropped, columns);
            });
        });

        return map;
    }

    public static splitTableByMetric<T>(data: T[][], resources: FlattenedResource[], isTransposedData: boolean = false, keepFirstColumn: boolean = false): T[][][] {
        const tables: T[][][] = [];
        const columns = isTransposedData ? data : ArrayUtils.rotateArray(data);

        // Map to associate each unique metric with a table index
        const resourceMap = new Map<string, number>();

        resources.forEach((resource, index: number) => {
            // Generate a unique key for each metric
            const key = `${resource.metricKey}${resource.aggregationOverTime}${resource.metricIndex}`;

            this.addColumnsToResourceMapAndTables(resourceMap, key, tables, columns, index, !isTransposedData || keepFirstColumn);
        });

        return tables.map((table: T[][]) => (isTransposedData ? table : ArrayUtils.rotateArray(table)));
    }

    public static splitTableByResource<T>(data: T[][], resources: FlattenedResource[], isTransposedData: boolean = false, keepFirstColumn: boolean = false): T[][][] {
        const tables: T[][][] = [];
        const columns = isTransposedData ? data : ArrayUtils.rotateArray(data);
        // map a resource to a table index
        const resourceMap = new Map<string, number>();

        resources.forEach((series, index: number) => {
            const key = Utils.hash({
                type: series.resourceType,
                key: series.resourceKey,
                name: series.resourceName,
                filters: series.resourceFilters,
                timeOverride: series.timeOverride,
            });

            this.addColumnsToResourceMapAndTables(resourceMap, key, tables, columns, index, !isTransposedData || keepFirstColumn);
        });

        return tables.map((table: T[][]) => (isTransposedData ? table : ArrayUtils.rotateArray(table)));
    }

    private static addColumnsToResourceMapAndTables<T>(resourceMap: Map<string, number>, key: string, tables: T[][][], columns: T[][], index: number, keepFirstNonTransposedColumn: boolean) {
        if (!resourceMap.has(key)) {
            // Each metric gets a new table
            resourceMap.set(key, tables.length);
            tables.push(keepFirstNonTransposedColumn ? [columns[0]] : []);
        }

        // If data is transposed, we add each row as is (each row represents a metric over time), index = index
        // For non-transposed data, add the appropriate metric column to the table, index = index + 1
        const columnIndex = keepFirstNonTransposedColumn ? index + 1 : index;
        const tableIndex = resourceMap.get(key)!;
        tables[tableIndex].push(columns[columnIndex]);
    }

    public static getTableColumnCount(widget: WidgetConfig): number[] {
        // logs
        if (widget.type === WidgetType.Logs) {
            // may actually be more tables, based on grouping
            return [7];
        }

        // logs
        if (widget.type === WidgetType.LatestLogs) {
            return [5];
        }

        // operator forecast (normal + monetary)
        if (widget.type === WidgetType.OperatorForecast) {
            return [11];
        }

        // normal table
        const groupBy = widget.config.tableConfig?.groupDataBy as DataGroupType || undefined;
        const columnCounts: number[] = [];
        switch (groupBy) {
            case DataGroupType.DATASOURCE: {
                const resources = WidgetDataUtils.flattenResources(widget);
                const columns = resources.map((it) => it.metricName);
                columns.unshift('time');
                WidgetDataUtils.splitTableByResource([columns], resources, widget.config.tableConfig?.transposeData, true)
                    .map((it) => it[0])
                    .forEach((it) => columnCounts.push(it.length));
                break;
            }
            case DataGroupType.METRIC: {
                const resources = WidgetDataUtils.flattenResources(widget);
                const columns = resources.map((it) => it.seriesName || it.resourceName);
                columns.unshift('time');
                WidgetDataUtils.splitTableByMetric([columns], resources, widget.config.tableConfig?.transposeData, true)
                    .map((it) => it[0])
                    .forEach((it) => columnCounts.push(it.length));
                break;
            }
            case DataGroupType.NONE:
            default:
                columnCounts.push(widget.axis
                    .flatMap((it) => it.metrics)
                    .flatMap((it) => it.resources)
                    .length + 1);
        }
        return columnCounts;
    }

    public static getTableSortingOptions(widget: WidgetConfig): SelectBoxOption[] {
        let dataSortingOptions: SelectBoxOption[];
        const groupBy = widget.config.tableConfig?.groupDataBy as DataGroupType || undefined;
        if (groupBy === DataGroupType.DATASOURCE) {
            dataSortingOptions = widget.axis
                .flatMap((it) => it.metrics)
                .map((it) => {
                    const aggregation = WidgetDataUtils.getAggregationSymbol(it.aggregationOverTime);
                    return {
                        value: `${it.uuid}:`, // specific metric, any resource
                        displayName: `${it.metricName} ${aggregation}`.trim(),
                    };
                });
        } else if (groupBy === DataGroupType.METRIC) {
            dataSortingOptions = WidgetUtils.getDistinctResources(widget)
                .map((it) => ({
                    value: `:${it.uuid}`, // any metric, specific resource
                    displayName: `${it.resourceName}`.trim(),
                }));
        } else {
            const resources = WidgetDataUtils.flattenResources(widget);
            dataSortingOptions = resources.map((it) => {
                const metricName = `${it.metricName} ${WidgetDataUtils.getAggregationSymbol(it.aggregationOverTime)}`;
                const resourceName = `${it.seriesName || it.resourceName} ${WidgetDataUtils.getAggregationSymbol(it.aggregationOverGenerators, it.resourceType)}`.trim();
                return {
                    value: `${it.metricUUID}:${it.resourceUUID}`, // specific metric, specific resource
                    displayName: `${metricName} | ${resourceName}`,
                    displayHtml: `<div class="w-full border-t border-gray-100">
                        <span>${metricName}</span><br/>
                        <span class="text-sm">${resourceName}</span>
                    </div>`,
                };
            });
        }
        dataSortingOptions.unshift({ value: '', displayName: 'time' });
        return dataSortingOptions;
    }

    private static getAggregationSymbol(aggregation: Aggregation, resourceType?: ResourceType): string {
        const resourceTypesWithoutAggregation = [ResourceType.Generator, ResourceType.Anonymous];
        if (resourceType && resourceTypesWithoutAggregation.includes(resourceType)) {
            return '';
        }
        switch (aggregation) {
            case Aggregation.Avg: return '(Ø)';
            case Aggregation.Sum: return '(∑)';
            case Aggregation.Min: return '(min)';
            case Aggregation.Max: return '(max)';
            default:
                return '';
        }
    }
}
