import type { GeneratorStatusApi, GeneratorStatusRepresentation } from '@/modules/shared/adapter/GeneratorStatusApi';
import { GeneratorStatusApi as GeneratorStatusRestApi, Configuration } from '@/clients/dashboardapi/v2';
import { IndexedDBRepository } from '@/modules/shared/indexeddb';
import type { AuthApi } from '@/modules/shared/adapter';
import { AsyncDebouncer, AuthMiddleware, ConnectionResetMiddleware } from '@/modules/shared/adapter/rest/middleware';

export class CachedGeneratorStatusRestApi implements GeneratorStatusApi {

    private readonly generatorStatusApi: GeneratorStatusRestApi;
    private readonly statusCache: IndexedDBRepository<string, GeneratorStatusRepresentation>;

    constructor(indexedDb: Promise<IDBDatabase>, apis: { auth: AuthApi }) {
        const restApiConfig = new Configuration({
            accessToken: () => apis.auth.getAuthToken(),
            basePath: `${process.env.VUE_APP_SERVICE_API}v2`,
            credentials: 'include',
            middleware: [new AuthMiddleware(apis.auth), new ConnectionResetMiddleware()],
        });
        this.generatorStatusApi = new GeneratorStatusRestApi(restApiConfig);
        this.statusCache = new IndexedDBRepository<string, GeneratorStatusRepresentation>(indexedDb, 'generator-status');
    }

    public async getGeneratorStatus(locale?: string): Promise<GeneratorStatusRepresentation[]> {
        return await this.statusCache.findAllIfAnyCached() || this.fetchGeneratorStatus(locale);
    }

    private async fetchGeneratorStatus(locale?: string): Promise<GeneratorStatusRepresentation[]> {
        return AsyncDebouncer.debounce('CachedGeneratorStatusRestApi.fetchGeneratorStatus', async () => {
            const status = await this.generatorStatusApi.getAllGeneratorStatus(locale);
            await this.statusCache.saveAll(status);
            return status;
        });
    }
}
