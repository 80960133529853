
import Formatter from '@/assets/js/utils/Formatter';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import WidgetErrorMessage from '@/components/widgets/components/WidgetErrorMessage.vue';
import WidgetLoading from '@/components/widgets/components/WidgetLoading.vue';

import type { WidgetConfig } from '@/modules/ctx-dashboard';
import { WidgetType } from '@/modules/ctx-dashboard';
import TimeIntervals from '@/assets/js/utils/TimeIntervals';
import WidgetStreaming from '@/components/widgets/components/DashboardWidgetStreaming.vue';
import { Widget } from '@/components/widgets';
import { WidgetError } from '@/components/widgets/types/WidgetError';

@Component({
    components: {
        WidgetStreaming,
        WidgetLoading,
        WidgetErrorMessage,
    },
})
export default class WidgetLoader extends Vue {

    @Prop({ required: true })
    public readonly widget!: WidgetConfig;

    @Prop({ required: false, default: false })
    public readonly report!: boolean;

    @Prop({ required: false, default: false })
    public readonly expand!: boolean;

    @Prop({ required: true })
    public readonly enabledDataStreaming!: boolean;

    @Ref('widget')
    private readonly refWidget!: any|undefined;

    private error: WidgetError|null = null;
    private loading: boolean = true;
    private renderWidgetComponent: boolean = false;
    private subtitle: string = '';

    public created(): void {
        this.updateSubtitle();
    }

    public mounted(): void {
        this.$nextTick(() => this.renderWidgetComponent = true);
    }

    private get widgetComponent(): () => any {
        switch (this.widget.type) {
            case WidgetType.ScatterChart: return () => import(/* webpackChunkName: "widgets" */'./scatter/WidgetChartScatter.vue');
            case WidgetType.StackedTimeChart:
            case WidgetType.TimeChart: return () => import(/* webpackChunkName: "widgets" */'./timechart/WidgetTimeChart.vue');
            case WidgetType.StackedGeneratorChart:
            case WidgetType.GeneratorChart: return () => import(/* webpackChunkName: "widgets" */'./generatorchart/WidgetChartGenerator.vue');
            case WidgetType.Gauge:
            case WidgetType.Number: return () => import(/* webpackChunkName: "widgets" */'./number/WidgetNumber.vue');
            case WidgetType.Distribution: return () => import(/* webpackChunkName: "widgets" */'./distribution/WidgetDistribution.vue');
            case WidgetType.Logs: return () => import(/* webpackChunkName: "widgets" */'./logs/WidgetLogs.vue');
            case WidgetType.Availability:
            case WidgetType.Commissions:
            case WidgetType.Table: return () => import(/* webpackChunkName: "widgets" */'./table/WidgetTable.vue');
            case WidgetType.Map: return () => import(/* webpackChunkName: "widgets" */'./map/WidgetMap.vue');
            case WidgetType.Windrose: return () => import(/* webpackChunkName: "widgets" */'./windrose/WidgetWindrose.vue');
            case WidgetType.WeatherMap: return () => import(/* webpackChunkName: "widgets" */'./map-weather/WidgetWeatherMap.vue');
            case WidgetType.Forecast: return () => import(/* webpackChunkName: "widgets" */'./forecast/WidgetChartForecast.vue');
            case WidgetType.Heatmap: return () => import(/* webpackChunkName: "widgets" */'./heatmap/WidgetChartHeatmap.vue');
            case WidgetType.PowerCurve: return () => import(/* webpackChunkName: "widgets" */'./powercurve/WidgetChartPowercurve.vue');
            case WidgetType.Note: return () => import(/* webpackChunkName: "widgets" */'./note/WidgetNote.vue');
            case WidgetType.LatestLogs: return () => import(/* webpackChunkName: "widgets" */'./latest-logs/WidgetLatestLogs.vue');
            case WidgetType.OperatorForecast: return () => import(/* webpackChunkName: "widgets" */'./operator-forecast/WidgetOperatorForecast.vue');
            default:
        }
        return () => import('./WidgetInvalid.vue');
    }

    public getWidget(): Widget {
        return this.refWidget!;
    }

    public getMenuActions(): any[] {
        if (this.refWidget.getMenuActions) {
            return this.refWidget.getMenuActions();
        }
        return [];
    }

    private updateSubtitle(subtitle?: string): void {
        if (subtitle !== undefined) {
            this.subtitle = subtitle;
        } else if (this.widget.intervalName && this.widget.intervalName !== 'newest') {
            const interval = TimeIntervals.resolveIntervalForWidget(this.widget);
            const exclusive = TimeIntervals.hasExclusiveEndDate(this.widget?.intervalName || '');
            this.subtitle = Formatter.formatTimeInterval(interval.from, interval.to, false, exclusive);
        }
    }

    @Watch('loading')
    private initialized() {
        if (!this.loading) {
            // emit event in $nextTick after vue finished rendering
            this.$nextTick(() => this.$emit('loaded', this.refWidget));
        }
    }

    @Watch('error')
    private onError() {
        this.$emit('error', this.error);
    }
}

