
import { Component, Ref, Vue } from 'vue-property-decorator';

@Component
export default class Tooltip extends Vue {

    public static instance: Tooltip|null = null;

    @Ref('tooltip')
    private readonly tooltipRef!: HTMLElement|undefined;

    private readonly showDelay: number = 1000;

    private showTimeout: any = undefined;
    private html: boolean = false;
    private show: boolean = false;
    private text: string = '';
    private element: HTMLElement|null = null;
    private position: { x?: 'left'|'right'|'center'; y?: 'top'|'bottom'|'center' }|null = null;
    private tooltipStyle: any = {};
    private mouseEvent: MouseEvent|null = null;

    public created() {
        Tooltip.instance = this;
        document.addEventListener('scroll', () => this.hideTooltip(), { passive: true });
        document.addEventListener('resize', () => this.hideTooltip(), { passive: true });
    }

    public showTooltip(el: HTMLElement, binding: any) {
        if (this.showTimeout) {
            clearTimeout(this.showTimeout);
        }
        let positionMode: { x?: 'left'|'right'|'center'; y?: 'top'|'bottom'|'center' }|null = null;
        this.html = binding.modifiers.html;
        if (!binding.modifiers.follow) {
            positionMode = { x: undefined, y: undefined };
            if (binding.modifiers.left && !binding.modifiers.right) positionMode.x = 'left';
            if (binding.modifiers.right && !binding.modifiers.left) positionMode.x = 'right';
            if (binding.modifiers.center && !binding.modifiers.left && !binding.modifiers.right) positionMode.x = 'center';
            if (binding.modifiers.top && !binding.modifiers.bottom) positionMode.y = 'top';
            if (binding.modifiers.bottom && !binding.modifiers.top) positionMode.y = 'bottom';
            if (binding.modifiers.center && !binding.modifiers.bottom && !binding.modifiers.top) positionMode.y = 'center';
        }
        this.text = binding.value;
        this.position = positionMode;
        this.showTimeout = setTimeout(() => {
            this.show = true;
            this.element = el;
            this.tooltipStyle = this.calculatePosition(el);
            this.$nextTick(() => this.moveTooltipToVisible());
        }, this.showDelay);
    }

    public hideTooltip() {
        this.show = false;
        this.text = '';
        this.element = null;
    }

    public hideTooltipForElement(el: HTMLElement) {
        if (this.element === el) {
            this.hideTooltip();
        }
    }

    public updateMousePosition(event: MouseEvent): void {
        this.mouseEvent = event;
        if (this.position === null) {
            this.tooltipStyle = this.calculatePosition(event.target as HTMLElement);
        }
    }

    private get isInTopLeftCorner(): boolean {
        return this.tooltipStyle.left === '0px' && this.tooltipStyle.top === '0px';
    }

    private moveTooltipToVisible(): void {
        if (this.tooltipRef) {
            const rect = this.tooltipRef.getBoundingClientRect();
            const borderPadding = 0; // does not work correctly, tooltip sometimes gets bigger after moving
            if (rect.x < borderPadding) {
                this.tooltipStyle.left = `${borderPadding}px`;
            } else if (rect.x + rect.width > window.innerWidth - borderPadding) {
                this.tooltipStyle.left = `${window.innerWidth - rect.width - borderPadding}px`;
            }
            if (rect.y < borderPadding) {
                this.tooltipStyle.top = `${borderPadding}px`;
            } else if (rect.y + rect.height > window.innerHeight - borderPadding) {
                this.tooltipStyle.top = `${window.innerHeight - rect.height - borderPadding}px`;
            }
        }
    }

    private calculatePosition(el: HTMLElement): any {
        const position = { top: 0, left: 0 };
        const rect = el.getBoundingClientRect();
        if (this.position) {
            // position based on parent bounds
            switch (this.position.y || 'bottom') {
                case 'top': position.top = rect.y; break;
                case 'bottom': position.top = rect.y + rect.height; break;
                case 'center': position.top = rect.y + rect.height / 2; break;
                default: console.error('Invalid y position for tooltip!');
            }
            switch (this.position.x || 'right') {
                case 'left': position.left = rect.x; break;
                case 'right': position.left = rect.x + rect.width; break;
                case 'center': position.left = rect.x + rect.width / 2; break;
                default: console.error('Invalid x position for tooltip!');
            }
        } else if (this.mouseEvent && (rect.width > 32 || rect.height > 32)) {
            // position based on mouse position
            position.top = this.mouseEvent.y;
            position.left = this.mouseEvent.x;
        } else {
            // fallback: bottom right
            position.top = rect.y + rect.height;
            position.left = rect.x + rect.width;
        }
        return {
            top: `${position.top}px`,
            left: `${position.left}px`,
        };
    }
}

const TooltipPlugin = {
    install(vue: typeof Vue) {
        vue.directive('tooltip', {
            bind(el: HTMLElement, binding: any) {
                if (!Tooltip.instance) {
                    console.error('Tooltip instance not found!');
                    return;
                }
                el.addEventListener('mouseenter', () => Tooltip.instance?.showTooltip(el, binding));
                el.addEventListener('mouseleave', Tooltip.instance.hideTooltip);
                el.addEventListener('mousemove', Tooltip.instance.updateMousePosition);
            },
            unbind(el: HTMLElement, binding) {
                if (!Tooltip.instance) {
                    console.error('Tooltip instance not found!');
                    return;
                }
                el.removeEventListener('mouseenter', () => Tooltip.instance?.showTooltip(el, binding));
                el.removeEventListener('mouseleave', Tooltip.instance?.hideTooltip);
                el.removeEventListener('mousemove', Tooltip.instance?.updateMousePosition);
                Tooltip.instance.hideTooltipForElement(el);
            },
        });
    },
};

Vue.use(TooltipPlugin);
