import { AsyncDebouncer } from '@/modules/shared/adapter/rest/middleware';
import { Events } from '@/modules/shared/event-bus';
import { DataChangedEvent } from '@/modules/shared/types';

export interface StoreChangedEvent<K extends string|number = string> extends DataChangedEvent<K> {
    store: string;
}

interface CacheInvalidationOptions {
    invalidateOnReload: boolean;
    invalidateOnUserChanged: boolean;
    invalidateOnLanguageChanged: boolean;
    invalidateOnInterval: number;
}

export interface CachableEntity<K extends string|number = string> {
    key: K;
}

export class IndexedDBRepository<K extends string|number, T extends CachableEntity<K>> {

    private readonly database: Promise<IDBDatabase>;
    private readonly store: string;
    private readonly defaultCacheInvalidationOptions: CacheInvalidationOptions = {
        invalidateOnReload: true,
        invalidateOnUserChanged: true,
        invalidateOnLanguageChanged: true,
        invalidateOnInterval: 1000 * 60 * 60 * 6, // invalidate cache every 6 hours
    };
    private readonly cacheInvalidationOptions: CacheInvalidationOptions;
    private readonly listeners: ((event: StoreChangedEvent<K>) => void)[] = [];

    constructor(database: Promise<IDBDatabase>, store: string, cacheInvalidationOptions?: Partial<CacheInvalidationOptions>) {
        this.database = database;
        this.store = store;
        this.cacheInvalidationOptions = Object.assign(this.defaultCacheInvalidationOptions, cacheInvalidationOptions || {});
        if (this.cacheInvalidationOptions.invalidateOnReload) {
            this.removeAll();
        }
        if (this.cacheInvalidationOptions.invalidateOnInterval > 0) {
            setInterval(() => this.removeAll(), this.cacheInvalidationOptions.invalidateOnInterval);
        }
        if (this.cacheInvalidationOptions.invalidateOnUserChanged) {
            Events.instance.on('user-changed', () => this.removeAll());
        }
        if (this.cacheInvalidationOptions.invalidateOnLanguageChanged) {
            Events.instance.on('language-changed', () => this.removeAll());
        }
    }

    public addChangedListener(callback: (event: StoreChangedEvent<K>) => void): void {
        this.listeners.push(callback);
    }

    public async count(): Promise<number> {
        const database = await this.database;
        return new Promise<number>((resolve, reject) => {
            const transaction = database.transaction(this.store, 'readonly');
            const store = transaction.objectStore(this.store);
            const query = store.count();
            query.onsuccess = () => resolve(query.result);
            query.onerror = () => reject(query.error);
        });
    }

    public async findAllIfAnyCached(): Promise<T[]|undefined> {
        const cached = await this.findAll();
        if (cached.length > 0) {
            return cached;
        }
        return undefined;
    }

    public async findAll(): Promise<T[]> {
        const database = await this.database;
        return AsyncDebouncer.debounce(
            `IndexedDB.${this.store}.findAll`,
            async () => new Promise<T[]>((resolve, reject) => {
                const transaction = database.transaction(this.store, 'readonly');
                const store = transaction.objectStore(this.store);
                const query = store.getAll();
                let done = false;
                query.onsuccess = () => {
                    if (!done) {
                        done = true;
                        resolve(query.result.map((it) => it.value));
                    }
                };
                query.onerror = () => reject(query.error);
                const tenSecondTimeout = 10000;
                setTimeout(() => {
                    if (!done) {
                        done = true;
                        reject(new Error(`indexeddb read timeout in ${this.store} store`));
                    }
                }, tenSecondTimeout);
            }),
        );
    }

    public async findByKey(key: K): Promise<T|undefined> {
        if (!key) {
            return undefined;
        }
        const database = await this.database;
        return new Promise<T|undefined>((resolve, reject) => {
            const transaction = database.transaction(this.store, 'readonly');
            const store = transaction.objectStore(this.store);
            const query = store.get(key);
            query.onsuccess = () => {
                if (query.result && query.result.value) {
                    resolve(query.result.value);
                }
                resolve(undefined);
            };
            query.onerror = () => reject(query.error);
        });
    }

    public async save(entity: T): Promise<T> {
        const database = await this.database;
        return new Promise<T>((resolve, reject) => {
            const transaction = database.transaction(this.store, 'readwrite');
            const store = transaction.objectStore(this.store);
            const query = store.put({
                key: entity.key,
                updated: new Date(),
                value: entity,
            });
            query.onsuccess = () => {
                resolve(entity);
                this.emitChangeEvent('update', [entity.key]);
            };
            query.onerror = () => reject(query.error);
        });
    }

    public async saveAll(entities: T[]): Promise<T[]> {
        if (entities.length === 0) {
            return [];
        }
        const database = await this.database;
        return new Promise<T[]>((resolve, reject) => {
            const transaction = database.transaction(this.store, 'readwrite');
            const store = transaction.objectStore(this.store);
            entities.forEach((entity) => {
                store.put({
                    key: entity.key,
                    updated: new Date(),
                    value: entity,
                });
            });
            transaction.oncomplete = () => {
                resolve(entities);
                this.emitChangeEvent('update', entities.map((e) => e.key));
            };
            transaction.onerror = () => reject(transaction.error);
        });
    }

    public async removeByKey(key: K): Promise<void> {
        const database = await this.database;
        return new Promise<void>((resolve, reject) => {
            const transaction = database.transaction(this.store, 'readwrite');
            const store = transaction.objectStore(this.store);
            const query = store.delete(key);
            query.onsuccess = () => {
                resolve();
                this.emitChangeEvent('remove', [key]);
            };
            query.onerror = () => reject(query.error);
        });
    }

    public async remove(entity: T): Promise<void> {
        return this.removeByKey(entity.key);
    }

    public async removeAll(): Promise<void> {
        const database = await this.database;
        const allEntities = await this.findAll();
        return new Promise<void>((resolve, reject) => {
            const transaction = database.transaction(this.store, 'readwrite');
            const store = transaction.objectStore(this.store);
            const query = store.clear();
            query.onsuccess = () => {
                resolve();
                this.emitChangeEvent('clear', allEntities.map((e) => e.key));
            };
            query.onerror = () => reject(query.error);
        });
    }

    private async emitChangeEvent(action: 'update'|'remove'|'clear', keys: K[]): Promise<void> {
        if (keys.length > 0) {
            const event = {
                store: this.store,
                action: action,
                keys: keys,
            };
            this.listeners.forEach((callback) => {
                try {
                    callback(event);
                } catch (e: any) {
                    console.error(e);
                }
            });
            Events.instance.publishEvent(`${this.store}--changed`, event);
        }
    }
}
