export enum WidgetErrorType {
    Custom = 'custom',
    NoData = 'no-data',
    NoDataSource = 'no-data-source',
    IncompleteDataSources = 'incomplete-data-sources',
    NoPermissionForMetric = 'no-permission-for-metric',
    MissingLicenseFeature = 'missing-license-feature',
    MissingMetrics = 'missing-metrics',
    InvalidConfig = 'invalid-config',
    Service = 'service-error',
}
