
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GenericTable from '@/modules/shared/components/table/GenericTable.vue';
import { TableColumn } from '@/modules/shared/components/table/types';
import { Port } from '@/modules/shared/Port';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';

interface Generator {
    key: string;
    name: string;
    parkName: string;
    model: string;
    manufacturer: string;
    power?: number;
    installationDate?: number;
    zipCode: string;
    hubHeight?: number;
    selected?: boolean;
    active: boolean;
}

@Component({
    components: { GenericTable },
})
export default class GeneratorList extends Vue {

    @Prop({ required: false, default: () => [] })
    public readonly selected!: string[];

    @Prop({ required: false, default: null })
    public readonly portfolioKey!: string|null;

    @Prop({ required: false, default: undefined })
    public readonly whitelist!: string[]|undefined;

    @Prop({ required: false, default: undefined })
    public readonly blacklist!: string[]|undefined;

    @Prop({ required: false, default: null })
    public readonly maxSelectable!: number|null;

    @Prop({ required: false, default: '' })
    public readonly filter!: string;

    @Prop({ required: false, default: true })
    public readonly multiSelection!: boolean;

    @Prop({ required: false, default: false })
    public readonly removeAction!: boolean;

    @Prop({ required: false, default: false })
    public readonly keepSelectionOnFilter!: boolean;

    private generators: Generator[] = [];
    private loading: boolean = false;

    public created(): void {
        this.fetchGenerators();
    }

    private get tableColumns(): TableColumn[] {
        if (this.removeAction) {
            return [
                { field: 'parkName', title: 'generators.park-generator', class: 'w-full' },
                { field: 'actions', title: '', class: 'w-32' },
            ];
        }
        return [
            { field: 'parkName', title: 'generators.park-generator', class: 'w-full' },
        ];
    }

    private get generatorsFiltered(): Generator[] {
        let generatorsFiltered = this.generators;
        if (this.filter) {
            const filters = this.filter.trim().toLowerCase().split(/\s/g);
            generatorsFiltered = generatorsFiltered.filter((generator) => {
                // any of the entered filter is found in zipcode
                if (filters.find((filter) => generator.zipCode.toLowerCase().includes(filter))) {
                    return true;
                }
                const generatorName = generator.name.replace(/\s/g, '').toLowerCase();
                const parkName = generator.parkName.replace(/\s/g, '').toLowerCase();
                const model = generator.model.replace(/\s/g, '').toLowerCase();
                const manufacturer = generator.manufacturer.replace(/\s/g, '').toLowerCase();
                // each filter must match at least one of the indexed fields
                return !filters.find((filter) => !generatorName.includes(filter)
                    && !parkName.includes(filter)
                    && !model.includes(filter)
                    && !manufacturer.includes(filter));
            });
        }
        return generatorsFiltered;
    }

    private get sortedGenerators(): Generator[] {
        return this.generatorsFiltered
            .sort((a, b) => (a.parkName || '').localeCompare(b.parkName || '')
                || (a.name || '').localeCompare(b.name || ''));
    }

    private get selectedGenerators(): Generator[] {
        return this.generators.filter((generator) => this.selected.includes(generator.key));
    }

    @Watch('whitelist')
    @Watch('blacklist')
    @Watch('filter')
    private async fetchGenerators(): Promise<void> {
        this.loading = true;
        const parks = await Port.parks.getParks();
        let generators = (await Port.generators.getGenerators()).map((generator) => {
            const park = parks.find((it) => it.key === generator.parkKey);
            return {
                key: generator.key,
                name: generator.name,
                hubHeight: generator.hubHeight,
                power: generator.power,
                installationDate: generator.installationDate,
                model: generator.model || '',
                manufacturer: generator.manufacturer || '',
                parkName: park?.name || '',
                zipCode: park?.zipCode || '',
                selected: this.selected.includes(generator.key),
                matchesFilter: true,
                active: generator.active || false,
            };
        });
        if (this.portfolioKey) {
            const portfolio = await Port.portfolios.getPortfolioByKey(this.portfolioKey);
            generators = generators.filter((gen) => portfolio?.generatorKeys?.includes(gen.key));
        }
        if (this.whitelist) {
            generators = generators.filter((generator) => this.whitelist!.includes(generator.key));
        }
        if (this.blacklist) {
            generators = generators.filter((generator) => !this.blacklist!.includes(generator.key));
        }
        this.generators = generators;
        this.$emit('update:count', this.generators.length);
        this.loading = false;
    }

    private updateSelected(generators: Generator[]): void {
        const selectedGeneratorKeys: string[] = generators
            .map((status) => status.key)
            .filter(ArrayUtils.removeDuplicates);
        if (this.generatorsFiltered.length === this.generators.length) {
            this.$emit('update:selected', selectedGeneratorKeys);
        } else {
            const visibleGeneratorKeys = this.generatorsFiltered.map((generator) => generator.key);
            const invisibleSelectedGeneratorKeys = this.selected.filter((statusKey) => !visibleGeneratorKeys.includes(statusKey));
            this.$emit('update:selected', selectedGeneratorKeys
                .concat(invisibleSelectedGeneratorKeys)
                .filter(ArrayUtils.removeDuplicates));
        }
    }

    private removeSelection(generatorKey: string): void {
        this.$emit('remove', generatorKey);
    }
}
