
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GenericTable from '@/modules/shared/components/table/GenericTable.vue';
import { TableColumn } from '@/modules/shared/components/table/types';
import { Port } from '@/modules/shared/Port';
import ArrayUtils from '@/assets/js/utils/ArrayUtils';

interface GeneratorStatus {
    key: string;
    dataProviderKey: string;
    name: string;
    color: string;
    selected: boolean;
}

@Component({
    components: { GenericTable },
})
export default class GeneratorStatusList extends Vue {

    @Prop({ required: false, default: () => [] })
    public readonly selected!: string[];

    @Prop({ required: false, default: undefined })
    public readonly whitelist!: string[]|undefined;

    @Prop({ required: false, default: undefined })
    public readonly blacklist!: string[]|undefined;

    @Prop({ required: false, default: null })
    public readonly maxSelectable!: number|null;

    @Prop({ required: false, default: '' })
    public readonly filter!: string;

    @Prop({ required: false, default: '' })
    public readonly dataProviderFilter!: string;

    @Prop({ required: false, default: true })
    public readonly multiSelection!: boolean;

    @Prop({ required: false, default: false })
    public readonly removeAction!: boolean;

    @Prop({ required: false, default: false })
    public readonly showDataProvider!: boolean;

    private generatorStatus: GeneratorStatus[] = [];

    public created(): void {
        this.fetchGeneratorStatus();
    }

    private get tableColumns(): TableColumn[] {
        let columns: TableColumn[] = [
            { field: 'name', title: 'name', class: 'w-full' },
            { field: 'dataProviderKey', title: '', class: 'w-64' },
            { field: 'color', title: '', class: 'w-32' },
            { field: 'actions', title: '', class: 'w-32' },
        ];
        if (!this.removeAction) {
            columns = columns.filter((col) => col.field !== 'actions');
        }
        if (!this.showDataProvider) {
            columns = columns.filter((col) => col.field !== 'dataProviderKey');
        }
        return columns;
    }

    private get selectedGeneratorStatus(): GeneratorStatus[] {
        return this.generatorStatus.filter((status) => this.selected.includes(status.key));
    }

    private get sortedGeneratorStatus(): GeneratorStatus[] {
        return this.generatorStatusFiltered.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }

    @Watch('whitelist')
    @Watch('blacklist')
    private async fetchGeneratorStatus(): Promise<void> {
        let generatorStatus = await Port.generatorStatus.getGeneratorStatus(this.$i18n.locale)
            .then((status) => status.map((s) => ({
                key: s.key,
                dataProviderKey: s.dataProviderKey,
                name: s.name.trim(), // some status have leading spaces...
                color: s.color,
                selected: this.selected.includes(s.key),
            })));
        if (this.whitelist) {
            generatorStatus = generatorStatus.filter((status) => this.whitelist!.includes(status.key));
        }
        if (this.blacklist) {
            generatorStatus = generatorStatus.filter((status) => !this.blacklist!.includes(status.key));
        }
        this.generatorStatus = generatorStatus;
        this.$emit('update:count', this.generatorStatus.length);
    }

    private get generatorStatusFiltered(): GeneratorStatus[] {
        let generatorStatusFiltered = this.generatorStatus;
        if (this.dataProviderFilter) {
            generatorStatusFiltered = generatorStatusFiltered.filter((status) => status.dataProviderKey === this.dataProviderFilter);
        }
        if (this.filter) {
            const filters = this.filter.trim().toLowerCase().split(/\s/g);
            generatorStatusFiltered = generatorStatusFiltered.filter((status) => {
                // any of the entered filters is found in status key
                if (filters.find((filter) => status.key.toString().includes(filter))) {
                    return true;
                }
                const statusName = status.name.replace(/\s/g, '').toLowerCase();
                // each filter must match at least one of the indexed fields
                return !filters.find((filter) => !statusName.includes(filter)
                    && !status.dataProviderKey.includes(filter));
            });
        }
        return generatorStatusFiltered;
    }

    private updateSelected(generatorStatus: GeneratorStatus[]): void {
        const selectedStatusKeys: string[] = generatorStatus
            .map((status) => status.key)
            .filter(ArrayUtils.removeDuplicates);
        if (this.generatorStatusFiltered.length === this.generatorStatus.length) {
            this.$emit('update:selected', selectedStatusKeys);
        } else {
            const visibleStatusKeys = this.generatorStatusFiltered.map((status) => status.key);
            const invisibleSelectedStatusKeys = this.selected.filter((statusKey) => !visibleStatusKeys.includes(statusKey));
            this.$emit('update:selected', selectedStatusKeys
                .concat(invisibleSelectedStatusKeys)
                .filter(ArrayUtils.removeDuplicates));
        }
    }

    private removeSelection(key: string): void {
        this.$emit('remove', key);
    }
}
